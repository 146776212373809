export interface ErrorObject {
  response?: {
    data?: {
      statusDesc?: string
      error?: string
      errors?: string | { [key: string]: string }
      detail?: string
      non_field_errors?: string[]
    }
  }
  data?: {
    errors?: string | { [key: string]: string } | string
  }
  message?: string
}

export const resolveErrorMessage = (e: ErrorObject) => {
  let errorMessage

  try {
    errorMessage =
      e.response?.data?.statusDesc ||
      e.response?.data?.error ||
      e.response?.data?.errors ||
      e.response?.data?.detail ||
      e.response?.data?.non_field_errors?.join(',') ||
      e.data?.errors ||
      e.message ||
      e.toString()
  } catch {
    errorMessage = 'An error has occurred'
  } finally {
    if (!errorMessage || typeof errorMessage !== 'string')
      errorMessage = 'An error has occurred'
  }

  return errorMessage
}

export default resolveErrorMessage
