export const scrollToEl = (
  el: HTMLElement | null,
  margin = 0,
  behavior: 'smooth' | 'auto' | undefined
) => {
  if (!el) return

  const { top } = el.getBoundingClientRect()

  window.scrollTo({
    top: window.scrollY + top - margin,
    behavior,
  })
}

export const scrollToNamedEl = (name: string) => {
  const selector = `[name="${name}"]`
  const element = document.querySelector(selector)

  if (element) {
    // Scroll to parent in case element is a hidden field
    scrollToEl(element.parentElement, window.innerHeight / 2, 'smooth')
  }
}

// TODO: scrollToEl should be replaced with this
export const scrollNamedElementIntoView = (name: string) => {
  const selector = `[name="${name}"]`
  const element = document.querySelector(selector)

  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}
