import React, { useCallback, useEffect, useMemo } from 'react'
import { useField, useFormikContext, FormikValues } from 'formik'
import Dropzone from '../../Dropzone/Dropzone'
import { Props as DropzoneFile } from '../../Dropzone/DropzoneFile'
import { formUtil } from '@/utils'

interface Props {
  name: string
  heading: string | JSX.Element
  description?: string | JSX.Element
  accept?: string
  maxFiles?: number
  maxSize?: number
  uploadFile: (file: File) => Promise<DropzoneFile>
  removeFile: (id: string) => Promise<void>
  className?: string
}

const FormDropzone: React.FC<Props> = ({
  name,
  uploadFile,
  removeFile,
  className,
  ...rest
}) => {
  const { setFieldValue, setFieldTouched, setFormikState } = useFormikContext()
  const [{ value = [] }, meta] = useField<DropzoneFile[]>(name)

  // Set field touched once at least one file is uploaded
  useEffect(() => {
    if (value.length > 0 && !meta.touched) {
      setFieldTouched(name, true)
    }
  }, [name, value, meta.touched, setFieldTouched])

  const handleSetFiles = useCallback(
    (files: File[]) => {
      setFieldValue(name, [
        ...value,
        ...files
          .filter(({ name }) => !value.find((f) => f.name === name))
          .map((file) => ({
            name: file.name,
            file,
          })),
      ])
    },
    [name, setFieldValue, value]
  )

  const decoratedFiles = useMemo(
    () =>
      value.map(({ id, name: fileName, file }: DropzoneFile) => {
        return {
          id,
          name: fileName,
          file,
          handleUpload: async () => {
            if (file) {
              const data: DropzoneFile = await uploadFile(file)

              setFormikState((state) => {
                const currentDocs = formUtil.lookupValue(
                  state.values as FormikValues,
                  name
                ) as DropzoneFile[]

                // replace matching file with uploaded file
                const updatedDocs = currentDocs.map((f: DropzoneFile) => {
                  return f.name === fileName ? data : f
                })

                return {
                  ...state,
                  values: formUtil.updateValues(
                    state.values as FormikValues,
                    name,
                    updatedDocs
                  ),
                }
              })
            }
          },
          handleRemove: async () => {
            // Only make network request if file was uploaded
            if (id) {
              await removeFile(id)
            }

            setFormikState((state) => {
              const currentDocs = formUtil.lookupValue(
                state.values as FormikValues,
                name
              ) as DropzoneFile[]

              // remove matching file
              const updatedDocs = currentDocs.filter(
                (f: DropzoneFile) => f.name !== fileName
              )

              return {
                ...state,
                values: formUtil.updateValues(
                  state.values as FormikValues,
                  name,
                  updatedDocs
                ),
              }
            })
          },
        }
      }),
    [name, value, setFormikState, uploadFile, removeFile]
  )

  return (
    <Dropzone
      {...rest}
      name={name}
      files={decoratedFiles}
      setFiles={handleSetFiles}
      className={className}
    />
  )
}

export default FormDropzone
