import { getUser, User } from '@/services/api/user/user'
import useApi, { ApiRes } from '../useApiV2'
import { resolveUser } from '@/services/api/resolvers'

const useUser = (userId?: string | null) => {
  const { data, isLoading, isError }: ApiRes<User | null> = useApi({
    key: userId || false, // request won't run if userId is falsy
    request: () => getUser(userId as string),
    resolve: (res: { data: User }) => resolveUser(res.data),
  })

  // If userId is undefined, then return undefined to indicate that
  // we don't know if we have the user. E.g., we may be in limbo restoring
  // the user from localStorage.
  if (typeof userId === 'undefined') {
    return { user: undefined, isLoading: false, isError: null }
  }

  // If userId is null, return null to indicate that we know we don't have a user
  if (userId === null) {
    return { user: null, isLoading: false, isError: null }
  }

  // Else userId is truthy, so return the result of the request
  return { user: data, isLoading, isError }
}

export default useUser
