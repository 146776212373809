import { useEffect, useCallback, useRef } from 'react'
import Script from 'next/script'
import { useRouter } from 'next/router'
import SegmentHandler from '@/services/analytics/SegmentHandler'

interface Props {
  campaign: { slug: string; regulationType: string }
}

const Analytics: React.FC<Props> = ({ campaign }) => {
  const router = useRouter()
  const campaignRef = useRef(campaign)

  const handleScreenChange = useCallback((url) => {
    const { page } = SegmentHandler.getState()
    const previous = page?.last?.properties?.to

    SegmentHandler.page({
      from: previous || url,
      to: url,
      project: campaignRef.current?.slug,
      regulation_type: campaignRef.current?.regulationType,
    })
  }, [])

  useEffect(() => {
    handleScreenChange(router.asPath)

    router.events.on('routeChangeComplete', handleScreenChange)

    return () => {
      router.events.off('routeChangeComplete', handleScreenChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    campaignRef.current = campaign
  }, [campaign])

  return (
    <>
      {process.env.NODE_ENV === 'production' && !!process.browser ? (
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-NNFP6WR');`,
          }}
        />
      ) : null}
    </>
  )
}

export default Analytics
