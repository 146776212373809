import { casingUtil } from '@/utils'
import {
  Investment,
  InvestmentForm,
  InvestmentPayload,
  InvestmentMeta,
  InvestmentMetaPayload,
} from './types'
import {
  resolveBirthday,
  resolveBirthdayPayload,
  resolvePhoneNumber,
} from '../resolvers/resolvers'

export const resolveInvestment = ({
  account,
  has_ssn,
  birthday,
  phone_number,
  ...rest
}: InvestmentPayload) => {
  return {
    ...casingUtil.snakeToCamel(rest),
    accountId: account,
    hasSsn: has_ssn,
    birthday: resolveBirthday(birthday),
    phoneNumber: resolvePhoneNumber(phone_number),
  } as Investment
}

export const resolveInvestmentPayload = ({
  accountId,
  amount,
  birthday,
  ...rest
}: InvestmentForm) => {
  return {
    ...casingUtil.camelToSnake(rest),
    account: accountId,
    amount: typeof amount === 'string' ? parseFloat(amount) : amount,
    birthday: resolveBirthdayPayload(birthday),
  }
}

export const resolveInvestmentMeta = (meta: InvestmentMetaPayload) => {
  const {
    income: yearlyIncome,
    net_worth: netWorth,
    invested_elsewhere_past_year: otherInvestmentsPastYear,
    ...rest
  } = meta || {}

  return {
    ...rest,
    yearlyIncome,
    netWorth,
    otherInvestmentsPastYear,
  }
}

export const resolveInvestmentMetaPayload = (
  meta: Omit<InvestmentMeta, 'id'>
) => {
  const {
    yearlyIncome: income,
    netWorth: net_worth,
    otherInvestmentsPastYear: invested_elsewhere_past_year,
    user,
  } = meta || {}

  return {
    income,
    net_worth,
    invested_elsewhere_past_year,
    user,
  }
}
