import React from 'react'
import Script from 'next/script'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import ZendeskLauncher from './ZendeskLauncher'
import useSiteAssets from '@/services/hooks/useSiteAssets'

const Zendesk = () => {
  const siteAssets = useSiteAssets()
  const router = useRouter()
  const path = router.asPath.split('?')[0]
  const isInvestPath =
    path.split('/')[1] === 'invest' || path.split('/')[2] === 'verify'

  if (!siteAssets?.zendeskKey) {
    return null
  }

  return (
    <>
      {/* Hide default widget button */}
      <style>{`iframe#launcher { display: none; }`}</style>

      {/* Load zendesk script */}
      <Script
        strategy="afterInteractive"
        id="ze-snippet"
        src={`https://static.zdassets.com/ekr/snippet.js?key=${siteAssets.zendeskKey}`}
      />

      {/* Render custom widget button */}
      <ZendeskLauncher
        className={classNames({
          'fixed bottom-[var(--zendesk-bottom-position)] lg:bottom-4 right-4 h-[48px] w-[48px] md:w-auto md:px-5 rounded-full z-[9998]':
            true,
          'hidden md:block': isInvestPath,
        })}
        text="Help"
      />
    </>
  )
}

export default Zendesk
