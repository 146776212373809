import client from '../thinMintClient'
import {
  InvestmentForm,
  InvestmentPayload,
  InvestmentMeta,
  InvestmentMetaPayload,
  InvestmentPerk,
} from './types'
import {
  resolveInvestment,
  resolveInvestmentPayload,
  resolveInvestmentMeta,
  resolveInvestmentMetaPayload,
} from './resolver'

const baseEndpoint = '/investments'

export const getAllInvestments = () => {
  return {
    key: `${baseEndpoint}/all`,
    request: () => client.get(`${baseEndpoint}/`),
    resolve: (res: { data: { results: InvestmentPayload[] } }) =>
      res.data.results.map(resolveInvestment),
  }
}

export const createInvestment = () => {
  return {
    request: (payload: InvestmentForm) =>
      client.post(`${baseEndpoint}/`, resolveInvestmentPayload(payload)),
    resolve: (res: { data: InvestmentPayload }) => resolveInvestment(res.data),
  }
}

export const updateInvestment = (investmentId: string) => {
  return {
    request: (payload: InvestmentForm) =>
      client.put(
        `${baseEndpoint}/${investmentId}/`,
        resolveInvestmentPayload(payload)
      ),
    resolve: (res: { data: InvestmentPayload }) => resolveInvestment(res.data),
  }
}

export const getInvestmentMeta = () => {
  return {
    key: `${baseEndpoint}/meta`,
    request: () => client.get(`/user-investment-meta/`),
    resolve: (res: { data: { results: InvestmentMetaPayload[] } }) => {
      return resolveInvestmentMeta(res.data.results[0])
    },
  }
}

export const upsertInvestmentMeta = (investmentMetaId: string | undefined) => {
  return {
    request: (payload: Omit<InvestmentMeta, 'id'>) => {
      if (investmentMetaId) {
        return client.put(
          `/user-investment-meta/${investmentMetaId}/`,
          resolveInvestmentMetaPayload(payload)
        )
      } else {
        return client.post(
          `/user-investment-meta/`,
          resolveInvestmentMetaPayload(payload)
        )
      }
    },
    resolve: (res: { data: InvestmentMetaPayload }) =>
      resolveInvestmentMeta(res.data),
  }
}

export const getUserInvestmentPerks = () => {
  return {
    key: `${baseEndpoint}/user-perks`,
    request: () => client.get(`/user-perks/`),
    resolve: (res: { data: { results: InvestmentPerk[] } }) => {
      return res.data.results
    },
  }
}

export const cancelInvestment = (investmentId: string) => {
  return {
    key: `${baseEndpoint}/all`,
    request: () =>
      client.post(`${baseEndpoint}/${investmentId}/cancel_investment/`, {}),
    resolve: (res: { data: { id: string } }) => res.data,
  }
}

export const reconfirmInvestment = (investmentId: string) => {
  return {
    key: `${baseEndpoint}/all`,
    request: () =>
      client.post(`${baseEndpoint}/${investmentId}/reconfirm/`, {}),
    resolve: (res: { data: InvestmentPayload }) => resolveInvestment(res.data),
  }
}

export default {
  baseEndpoint,
  getAllInvestments,
  createInvestment,
  updateInvestment,
  getInvestmentMeta,
  upsertInvestmentMeta,
  getUserInvestmentPerks,
  cancelInvestment,
  reconfirmInvestment,
}
