import React, { useCallback, useMemo } from 'react'
import { useField } from 'formik'
import classNames from 'classnames'
import NumberFormat from 'react-number-format'
import { Input } from '../FormBuilder/types'
import { CreditCard, Visa, Mastercard, Discover } from '@/components/svg'
import { creditCardUtil } from '@/utils'

export interface Props extends Input {
  name: string
  onChange: (e: React.ChangeEvent) => void
}

const FormNumberInput: React.FC<Props> = ({
  name,
  value,
  onChange,
  className,
  ...rest
}) => {
  const [, meta] = useField(name)

  const hasError = !!meta.error && meta.touched

  const handleValueChange = useCallback(
    ({ value: changeValue }: { value: string }) => {
      const event = {
        target: { name, value: changeValue },
      } as unknown as React.ChangeEvent
      onChange(event)
    },
    [onChange, name]
  )

  const Icon = useMemo(() => {
    const cardType = creditCardUtil.detectType(value || '')
    if (cardType === 'Visa' && !hasError) return Visa
    if (cardType === 'Mastercard' && !hasError) return Mastercard
    if (cardType === 'Discover' && !hasError) return Discover
    return CreditCard
  }, [value, hasError])

  return (
    <div className="relative">
      <div className="absolute top-0 left-0 bottom-0 w-12 px-2 flex items-center pointer-events-none">
        <Icon
          className={classNames('w-full', {
            'text-gray-5': !hasError,
            'text-red': hasError,
          })}
        />
      </div>

      <NumberFormat
        {...rest}
        name={name}
        value={value}
        format="#### #### #### ####"
        onValueChange={handleValueChange}
        className={classNames(className, 'pl-12')}
      />
    </div>
  )
}

export default FormNumberInput
