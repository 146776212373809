exports.FUNDING_SPACE_ID = '5a0zbg81idj6'

exports.CONTENT_BLOCK_LAYOUT = Object.freeze({
  BIO: 'bio',
  CARD: 'card',
  PERKS: 'perks',
  TESTIMONIAL: 'testimonial',
})

exports.getSiteAssetsID = (siteDomain = '') => {
  const allowedDomains = ['invest.angel.com', 'thedavidmovie.com']

  return allowedDomains.includes(siteDomain) ? siteDomain : 'invest.angel.com'
}
