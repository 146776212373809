import client from '../thinMintClient'
import { resolveCampaign } from './resolver'
import { SnakeToCamel } from '../types'
import { whiteLabelUtil } from '@/utils'

const isAllowedCampaign = whiteLabelUtil.isAllowed

const baseEndpoint = '/campaign'

export interface IssuerDocument {
  document: string
  name: string
}

export interface Perk {
  description_long: string
  description_short: string
  id: number
  name: string
  qualifying_investment_amount: number
}

export interface PriorRaise {
  amount_raised: number
  close_date: string
  num_backers: number | null
  round_type: string
}

export interface StretchGoal {
  amount: number
  title: string
}

export interface CampaignPayload {
  a_x_company: string // required
  accept_after_buffer: boolean | null
  accept_terms_certificate_language: string | null
  agreements_linking_to_subscription_agreement: string | null
  bank_account_number: string | null
  bank_address_1: string | null
  bank_address_2: string | null
  bank_name: string | null
  bank_routing: string | null
  bonus_period_over: boolean | null
  capital_goal: number | null
  capital_limit: number | null
  capital_pledged: number | null
  capital_raised: number | null
  close_date: string | null
  contact_email: string | null
  created: string | null
  creators: string[] | null
  currently_funding: boolean | null
  days_remaining_estimate: number | null
  days_until_close: number | null
  enabled: boolean | null
  ends_at: string | null
  escrow_agent_offering_id: string | null
  escrow_agent: 'North Capital' | 'Prime Trust' // required
  facebook_tracking_pixel: string | null
  fully_disbursed_at: string | null
  hours_remaining_estimate: number | null
  hours_until_close: number | null
  investors_total: number | null
  issuer_documents: IssuerDocument[] | []
  modified: string | null
  name: string
  offering_model: 'equity' | 'loan' | 'reservation' // required
  perks: Perk[] | null
  pre_money_valuation: number | null
  preview_img: string | null
  previous_round: string | null
  prior_raises: PriorRaise[] | null
  regulation_type: 'A TIER-1' | 'A TIER-2' | 'CF' | 'testing_the_waters' // required
  securities_offered_shortname: string // required
  securities_offered: string // required
  share_goal: number // required
  share_max_buffer: number // required
  share_minimum_target: number | null
  share_per_person_max: number // required
  share_per_person_min: number // required
  share_price: number // required
  share_reserved_total: number | null
  short_about: string | null
  signaturee_position: string | null
  signaturee: string | null
  slug: string // required
  stretch_goals?: StretchGoal[] | null
  termination_date: string | null
  termination_reason: string | null
  type: string | null
  users_pledged: number | null
  wire_instructions_pdf: string | null
  within_identity_verification_window: boolean
}

export interface Campaign
  extends Omit<
    SnakeToCamel<CampaignPayload>,
    | 'issuerDocuments'
    | 'perks'
    | 'priorRaises'
    | 'stretchGoals'
    | 'preMoneyValuation'
  > {
  amountRaised: number
  backers: number
  capitalRaised: number
  isInBuffer: boolean
  isSellingFast: boolean
  issuerDocuments: SnakeToCamel<IssuerDocument>[]
  maxInvestment: number
  minimumCapitalGoal: number
  minInvestment: number
  offeringString: string
  perks: SnakeToCamel<Perk>[]
  preMoneyValuation: number
  priorRaises: SnakeToCamel<PriorRaise>[]
  priorRaiseTotal: { amount: string; backers: number } | null
  progress: number
  progressString: string
  progressWithGoalString: string
  regulationTypeLabel: 'Testing the Waters' | 'CF' | 'A'
  stretchGoal: StretchGoal | null
  stretchGoals: SnakeToCamel<StretchGoal>[]
  timeRemaining: { timeRemainingValue: number; timeRemainingDimension: string }
  withinPaymentUpdateWindow: boolean
}

export const getAllCampaigns = () =>
  client
    .get(`${baseEndpoint}/`)
    .then((res: { data: CampaignPayload[] }) =>
      res.data.filter(isAllowedCampaign).map(resolveCampaign)
    )

export const getPartnerCampaigns = () =>
  client
    .get(`${baseEndpoint}/`)
    .then((res: { data: CampaignPayload[] }) =>
      res.data.filter((c) => !isAllowedCampaign(c)).map(resolveCampaign)
    )

export const getCampaign = (slug: string) =>
  client
    .get(`${baseEndpoint}/${slug}/`)
    .then((res: { data: CampaignPayload }) =>
      isAllowedCampaign(res.data) ? resolveCampaign(res.data) : null
    )

export const getUserInvestedCampaigns = () =>
  client
    .get(`${baseEndpoint}/`, { params: { user_invested_in: true } })
    .then((res: { data: CampaignPayload[] }) =>
      res.data.filter(isAllowedCampaign).map(resolveCampaign)
    )

export const getIssuerCampaigns = (issuerId: number) =>
  client
    .get(`${baseEndpoint}/?issuer=${issuerId}`)
    .then((res: { data: CampaignPayload[] }) =>
      res.data.filter(isAllowedCampaign).map(resolveCampaign)
    )

export default {
  baseEndpoint,
  getAllCampaigns,
  getPartnerCampaigns,
  getCampaign,
  getIssuerCampaigns,
  getUserInvestedCampaigns,
}
