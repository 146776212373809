import axios from 'axios'
import { ISiteAssetsFields } from 'src/types/generated/contentful'
import { getSiteName, resolveSiteAssets } from './resolver'

const BASE_ENDPOINT = '/api/site-assets'

export const getSiteAssets = () =>
  axios
    .get(`${BASE_ENDPOINT}?id=${encodeURIComponent(getSiteName())}`)
    .then((res: { data: { siteAssets: ISiteAssetsFields } }) =>
      resolveSiteAssets(res.data.siteAssets)
    )

export default { BASE_ENDPOINT, getSiteAssets }
