import React, { useCallback, useState } from 'react'
import { SiGoogle } from 'react-icons/si'
import { useGoogleLogin } from 'react-google-login'
import { googleLogin } from '@/services/api/auth'
import useAuth from '@/services/hooks/useAuth'
import { Button } from '../../components/common'
import { resolveErrorMessage, report } from '@/utils'

const GoogleAuthBtn = ({ setError }) => {
  const [initializationError, setInitializationError] = useState(null)
  const reqState = Math.ceil(Math.random() * 1000000000000)
  const { persistUser, closeModal } = useAuth()

  const onSuccess = useCallback(
    async ({ code }) => {
      setError('')

      try {
        const res = await googleLogin(reqState, code)
        persistUser(res.data.uuid, res.data.authorization)
        closeModal()
      } catch (err) {
        report.log(err)
        setError(resolveErrorMessage(err))
      }
    },
    [persistUser, closeModal, setError, reqState]
  )

  const onFailure = useCallback(
    (resp) => {
      if (resp.error === 'popup_closed_by_user') return

      if (resp.error === 'idpiframe_initialization_failed') {
        setInitializationError(
          'Please enable third party cookies in your browser to use Google Authentication.'
        )
        return
      }

      setError(resp.error)
    },
    [setError]
  )

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId:
      '286513033991-5muijues887k74a0jboah5au840sthvf.apps.googleusercontent.com',
    scope: 'openid email profile',
    responseType: 'code',
    accessType: 'offline',
    state: reqState,
    uxMode: 'redirect',
    redirectUri: `${location.protocol}://${location.host}`,
  })

  const onClick = useCallback(() => {
    if (initializationError) {
      setError(initializationError)
    } else {
      signIn()
    }
  }, [initializationError, setError, signIn])

  return (
    <Button size="sm" color="gray" className="w-full mt-3" onClick={onClick}>
      <div className="flex flex-row items-center justify-center w-full relative">
        <SiGoogle className="text-sm mr-2" />
        <span>Login with Google</span>
      </div>
    </Button>
  )
}

export default GoogleAuthBtn
