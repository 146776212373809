import React, { useState, useEffect, useRef, useCallback } from 'react'

const Iframe = (
  { src, style = {}, fadeInOnLoad = true, ...rest },
  forwardedRef
) => {
  const [iframeLoaded, setIframeLoaded] = useState(!fadeInOnLoad)
  const defaultRef = useRef()
  const iframe = forwardedRef || defaultRef

  const handleIframeLoad = useCallback(() => {
    setIframeLoaded(true)
  }, [setIframeLoaded])

  useEffect(() => {
    const ref = iframe.current

    ref.addEventListener('load', handleIframeLoad, true)

    return () => {
      ref?.removeEventListener('load', handleIframeLoad)
    }
  }, [iframe, handleIframeLoad])

  return (
    <iframe
      ref={iframe}
      title="iframe"
      src={src}
      style={{
        opacity: iframeLoaded ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out',
        ...style,
      }}
      {...rest}
    />
  )
}

export default React.forwardRef(Iframe)
