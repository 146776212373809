import React, { useCallback, useRef } from 'react'
import YouTubePlayer from 'yt-player'
import Modal, { Props as ModalProps } from '../Modal/Modal'
import { Close } from '@/components/svg'
import { getYoutubeVideoSegmentProperties } from '@/utils'
import ButtonBase from '../ButtonBase'
import VideoBox from '../VideoBox'
import SegmentHandler from '@/services/analytics/SegmentHandler'

interface Props extends Omit<ModalProps, 'children'> {
  videoId: string
  campaignSlug: string
  aspectRatio?: number
}

const VideoModal = ({
  setIsOpen,
  videoId,
  campaignSlug,
  aspectRatio,
  ...rest
}: Props) => {
  const playerRef = useRef<YouTubePlayer | null>(null)

  const handleClose = useCallback(() => {
    const videoProperties = getYoutubeVideoSegmentProperties(
      playerRef,
      campaignSlug
    )

    SegmentHandler.track('Video Playback Interrupted', {
      ...videoProperties,
      interruption_method: 'modal_dismissed',
    })

    setIsOpen(false)
  }, [setIsOpen, campaignSlug])

  return (
    <Modal {...rest} setIsOpen={handleClose} overlayColor="rgba(0,0,0,0.25)">
      <div className="relative w-[1068px] max-w-full 2xl:w-[1400px]">
        <div className="flex justify-end xl:pb-2">
          <ButtonBase onClick={handleClose} aria-label="Close Dialog">
            <Close className="w-6 h-6 xl:w-8 xl:h-8 text-white" />
          </ButtonBase>
        </div>

        <VideoBox
          id={`modal-video-${videoId}`}
          campaignSlug={campaignSlug}
          video={videoId}
          lazyload={false}
          className="w-full"
          ref={playerRef}
          aspectRatio={aspectRatio}
        />
      </div>
    </Modal>
  )
}

export default VideoModal
