import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useDropzone } from 'react-dropzone'
import Text from '../Text/Text'
import FilledButton from '../FilledButton'
import HeightTransitioner from '../HeightTransitioner'
import ErrorBox from '../ErrorBox'
import { UploadDocument } from '@/components/svg'
import DropzoneFile, { Props as DropzoneFileProps } from './DropzoneFile'
import { FILE_SIZE_LIMIT } from '@/services/formValidation'

export interface Props {
  name: string
  heading: string | JSX.Element
  description?: string | JSX.Element
  accept?: string
  maxFiles?: number
  maxSize?: number
  files: DropzoneFileProps[]
  setFiles: (files: File[]) => void
  disabled?: boolean
  className?: string
}

interface Rejected {
  errors: { code: string }[]
}

const Dropzone: React.FC<Props> = ({
  name,
  heading,
  description,
  accept = 'text/*,image/*,application/pdf',
  maxFiles = 10,
  maxSize = FILE_SIZE_LIMIT,
  files = [],
  setFiles,
  disabled = false,
  className,
}) => {
  const [rejectedFiles, setRejectedFiles] = useState<Rejected[]>([])

  const onDrop = useCallback(
    (accepted, rejected) => {
      setFiles(accepted)
      setRejectedFiles(rejected)
    },
    [setFiles, setRejectedFiles]
  )

  const isDisabled = disabled || files.length >= maxFiles

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    maxFiles: maxFiles - files.length,
    maxSize,
    disabled: isDisabled,
  })

  const warningText = useMemo(() => {
    const len = rejectedFiles.length
    if (len === 0) return null

    const tooMany = rejectedFiles.some(({ errors }) =>
      errors.some(({ code }) => code === 'too-many-files')
    )
    if (tooMany) {
      return `You can only upload ${maxFiles} ${
        maxFiles === 1 ? 'file' : 'files'
      }`
    }

    const maxSizeStr = maxSize / 1024 / 1024
    const acceptStr = accept.split(',').join(', ')
    return `${len} ${
      len > 1 ? 'files' : 'file'
    } rejected. Max file size is ${maxSizeStr} MB. Allowed file types ${
      accept.split(',').length > 1 ? 'are' : 'is'
    } ${acceptStr}.`
  }, [rejectedFiles, maxFiles, maxSize, accept])

  return (
    <div
      className={classNames(
        {
          'bg-gray-9 p-6 rounded-lg font-whitney': true,
        },
        className
      )}
    >
      <Text as="label" preset="heading.md">
        <span id={`label-${name}`}>{heading}</span>
      </Text>

      {description}

      <HeightTransitioner>
        <ul className="pt-1">
          {files.map((file: DropzoneFileProps) => (
            <li key={file.name} className="py-[2px] max-w-sm">
              <DropzoneFile
                {...file}
                handleRemove={async () => {
                  setRejectedFiles([])
                  if (file.handleRemove) {
                    await file.handleRemove()
                  }
                }}
              />
            </li>
          ))}
        </ul>

        <div className="pt-1">
          <ErrorBox
            errorText={warningText}
            color="yellow"
            size="sm"
            className="max-w-sm"
          />
        </div>
      </HeightTransitioner>

      <div
        {...getRootProps()}
        className={classNames({
          'relative bg-white p-6 border-gray-6 border-dashed border-2 rounded mt-3':
            true,
          'opacity-50 pointer-eventw-none cursor-default': isDisabled,
        })}
      >
        <input
          id={name}
          aria-labelledby={`label-${name}`}
          name={name}
          {...getInputProps()}
        />

        <div className="max-w-[324px] mx-auto text-center flex flex-col items-center">
          <UploadDocument className="w-24 h-24 text-oxide mb-4 -mt-2" />
          <Text as="p" preset="body.lg">
            Drag your documents or files here to start uploading
          </Text>
          <Text as="p" preset="body.lg" className="mt-2 mb-3 font-light">
            OR
          </Text>
          <FilledButton
            disabled={isDisabled}
            type="button"
            color="oxide"
            size="sm"
          >
            Browse files
          </FilledButton>
        </div>
      </div>
    </div>
  )
}

export default Dropzone
