import client from '../thinMintClient'
import { resolveParty, resolvePartyPayload } from './resolver'
import { SnakeToCamel } from '../types'

export interface PartyPayload {
  accredited_status: string
  address1: string
  address2: string
  city: string
  date_of_birth: string
  email: string
  escrow_agent_party_id: string
  first_name: string
  has_ssn: boolean
  id: number
  individual_account: number
  last_name: string
  middle_initial: string
  phone_number: string
  postal_code: string
  state: string
}

export interface Party
  extends SnakeToCamel<
    Omit<PartyPayload, 'date_of_birth' | 'state' | 'accredited_status'>
  > {
  birthday: string
  state: string
  accredited: boolean
}

export interface PartyForm extends Omit<Party, 'id'> {
  id?: number
  ssn?: boolean
}

const baseEndpoint = '/parties'

export const getAuthenticatedParty = () =>
  client
    .get(`${baseEndpoint}/`)
    .then((res: { data: { results: PartyPayload[] } }) =>
      resolveParty(res.data.results[0])
    )

export const createParty = (party: PartyForm) =>
  client
    .post(`${baseEndpoint}/`, resolvePartyPayload(party))
    .then((res: { data: PartyPayload }) => resolveParty(res.data))

export const updateParty = (partyId: number, party: PartyForm) =>
  client
    .put(`${baseEndpoint}/${partyId}/`, resolvePartyPayload(party))
    .then((res: { data: PartyPayload }) => resolveParty(res.data))

export default {
  baseEndpoint,
  getAuthenticatedParty,
  createParty,
  updateParty,
}
