import YouTubePlayer from 'yt-player'

interface UndocumentedYoutubePlayer extends YouTubePlayer {
  _player?: {
    getVideoData: () => {
      title: string
    }
    getPlaybackQuality: () => string
  }
}

const getYoutubeVideoSegmentProperties = (
  playerRef: { current: UndocumentedYoutubePlayer | null },
  campaignSlug: string
) => {
  const { title } = playerRef.current?._player?.getVideoData?.() || {}
  const video_quality = playerRef.current?._player?.getPlaybackQuality?.()

  return {
    title,
    content_id: playerRef.current?.videoId || 'unavailable',
    project: campaignSlug,
    total_length: playerRef.current?.getDuration(),
    position: playerRef.current?.getCurrentTime(),
    video_quality,
  }
}

export default getYoutubeVideoSegmentProperties
