import React, { useState } from 'react'
import Link from 'next/link'
import { MarkdownModal, Text } from '@/components/common'
import { AngelFundingLogo } from '@/components/svg'
import { whiteLabelUtil } from '@/utils'
import MenuItem from './MenuItem'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import InterstitialLink from '@/components/InterstitialLink'

const Footer: React.FC = () => {
  const [modalContent, setModalContent] = useState<string | undefined>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const siteAssets = useSiteAssets()

  const currentYear = new Date().getFullYear()

  const openModal = (content?: string) => {
    setModalContent(content)
    setIsModalOpen(true)
  }

  return (
    <footer className="font-whitney bg-black text-white text-xs pt-12 pb-4 px-2 md:pb-12 md:px-4">
      <div className="flex flex-col md:flex-row justify-center items-center text-center">
        <div className="inline-flex flex-col items-center">
          {whiteLabelUtil.isWhiteLabel() ? (
            <InterstitialLink domain="invest.angel.com">
              <Text as="p" preset="body.xs" className="mb-2">
                Powered by
              </Text>
              <AngelFundingLogo className="h-[40px] fill-white" />
            </InterstitialLink>
          ) : (
            <Link href="/">
              <a>
                <AngelFundingLogo className="h-[40px] fill-white" />
              </a>
            </Link>
          )}
          <Text as="p" preset="custom" className="mt-4 hidden md:block">
            COPYRIGHT © {currentYear}, ANGEL FUNDING.
          </Text>
          <Text as="p" preset="custom" className="hidden md:block">
            ALL RIGHTS RESERVED.
          </Text>
        </div>

        <menu className="inline-flex flex-col md:flex-row justify-center flex-wrap text-base text-center mt-5 md:mt-0 md:ml-6">
          <MenuItem
            label="Privacy"
            showItem={!!siteAssets?.privacy}
            onClick={() => openModal(siteAssets?.privacy)}
          />
          <MenuItem
            label="Terms of Service"
            showItem={
              !!(siteAssets?.termsOfServiceIntro || siteAssets?.termsOfService)
            }
            onClick={() =>
              openModal(
                [
                  siteAssets?.termsOfServiceIntro,
                  siteAssets?.termsOfService,
                ].join('\n')
              )
            }
          />
          <MenuItem
            label="How Crowdfunding Works"
            showItem={!!siteAssets?.howCrowdfundingWorks}
            onClick={() => openModal(siteAssets?.howCrowdfundingWorks)}
          />
          <MenuItem
            label="Electronic Consent"
            showItem={!!siteAssets?.electronicConsent}
            onClick={() => openModal(siteAssets?.electronicConsent)}
          />
          <MenuItem
            label="Portal Disclosures"
            showItem={!!siteAssets?.portalDisclosures}
            onClick={() => openModal(siteAssets?.portalDisclosures)}
          />
        </menu>

        <Text as="p" preset="custom" className="mt-4 md:hidden">
          COPYRIGHT © {currentYear}, ANGEL FUNDING.
        </Text>
        <Text as="p" preset="custom" className="md:hidden">
          ALL RIGHTS RESERVED.
        </Text>
      </div>

      <Text
        as="p"
        preset="body.sm"
        className="mt-10 max-w-4xl mx-auto text-center px-4"
      >
        {siteAssets?.footerDisclaimer}
      </Text>

      <MarkdownModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        info={modalContent}
      />
    </footer>
  )
}

export default Footer
