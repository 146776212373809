import Loader from 'react-loader-spinner'
import tailwind from '../../../../tailwind.config'
import useWindowSize from '@/services/hooks/useWindowSize'
import { layoutUtil } from '@/utils'

const PageLoader = () => {
  const { innerHeight, hydrated } = useWindowSize()

  if (!hydrated) {
    return null
  }

  return (
    <div
      className="h-full flex-1 flex justify-center items-center"
      style={{ height: innerHeight - layoutUtil.getHeaderHeight() }}
    >
      <Loader type="TailSpin" color={tailwind.theme.colors.ctaAccent} />
    </div>
  )
}

export default PageLoader
