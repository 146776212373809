import React, { useState, useCallback, CSSProperties } from 'react'
import classNames from 'classnames'
import { useField } from 'formik'
import { CountryCode } from 'libphonenumber-js/core'
import FormError from '../FormError'
import FormSelect from '../FormSelect/FormSelect'
import { Option } from '../../Select/Select'
import FormRadio from '../FormRadio'
import FormNumberInput from '../FormNumberInput'
import FormFileInput from '../FormFileInput'
import FormPhoneInput from '../FormPhoneInput'
import FormDropzone from '../FormDropzone/FormDropzone'
import { Props as DropzoneFile } from '../../Dropzone/DropzoneFile'
import ToggleInput from '../ToggleInput'
import FormSecretInput from '../FormSecretInput'
import Text from '../../Text/Text'
import InputLabelWrap from '../InputLabelWrap'
import FormInputDefault from './FormInputDefault'
import FormCreditCardInput from '../FormCreditCardInput'

interface Props {
  name: string
  displayName?: string | JSX.Element
  type?: string
  options?: Option[] | Option[][]
  text?: string | JSX.Element
  placeholder?: string
  maxFiles?: number
  maxSize?: number
  accept?: string
  format?: string
  prefix?: string
  thousandSeparator?: boolean
  decimalScale?: number
  isAllowed?: ({ floatValue }: { floatValue: number }) => boolean
  uploadFile?: (file: File) => Promise<DropzoneFile>
  removeFile?: (id: string) => Promise<void>
  helpText?: string
  isSecret?: boolean
  disabled?: boolean
  autoComplete?: string
  countries?: CountryCode[]
  className?: string
  style?: CSSProperties
}

type OnChange = (e: React.ChangeEvent) => void

interface RenderedInput extends Props {
  field: {
    value: string
    onChange: OnChange
  }
  isSecretMasked: boolean
  toggleSecret: () => void
  hasError: boolean
}

interface RenderedFileInput extends Props {
  field: {
    value: File[]
    onChange: OnChange
  }
  hasError: boolean
}

const renderFileInput = ({
  field,
  name,
  displayName,
  maxFiles = 0,
  uploadFile,
  removeFile,
  text,
  hasError,
  ...rest
}: RenderedFileInput) => {
  if (displayName && maxFiles > 1 && uploadFile && removeFile) {
    return (
      <FormDropzone
        {...rest}
        name={name}
        heading={displayName}
        description={text}
        maxFiles={maxFiles}
        uploadFile={uploadFile}
        removeFile={removeFile}
        className="mt-2"
      />
    )
  }

  if (displayName) {
    return (
      <InputLabelWrap name={name} label={displayName} hasError={hasError}>
        <FormFileInput {...rest} name={name} value={field.value} />
      </InputLabelWrap>
    )
  }
}

const renderInput = ({
  field,
  name,
  displayName,
  placeholder,
  isSecret,
  isSecretMasked,
  toggleSecret,
  type,
  options,
  text,
  hasError,
  disabled,
  countries,
  ...rest
}: RenderedInput) => {
  if (type === 'toggle' && text) {
    return <ToggleInput name={name} text={text} />
  }

  if (!displayName) {
    return null
  }

  const wrapProps = {
    name,
    value: field.value,
    label: displayName,
    placeholder,
    isSecret,
    isSecretMasked,
    toggleSecret,
    hasError,
    disabled,
  }

  const inputProps = {
    ...field,
    ...rest,
    name,
    id: name,
    disabled,
  }

  if (isSecretMasked) {
    return (
      <InputLabelWrap {...wrapProps}>
        <FormSecretInput {...inputProps} type={type} />
      </InputLabelWrap>
    )
  }

  if (type === 'select' && options) {
    return (
      <InputLabelWrap {...wrapProps}>
        <FormSelect {...inputProps} options={options} />
      </InputLabelWrap>
    )
  }

  if (type === 'radio' && options) {
    return <FormRadio {...inputProps} label={displayName} options={options} />
  }

  if (type === 'number') {
    return (
      <InputLabelWrap {...wrapProps}>
        <FormNumberInput {...inputProps} />
      </InputLabelWrap>
    )
  }

  if (type === 'tel') {
    return (
      <InputLabelWrap {...wrapProps}>
        <FormPhoneInput {...inputProps} countries={countries} />
      </InputLabelWrap>
    )
  }

  if (type === 'creditCard') {
    return (
      <InputLabelWrap {...wrapProps}>
        <FormCreditCardInput {...inputProps} />
      </InputLabelWrap>
    )
  }

  return (
    <InputLabelWrap {...wrapProps}>
      <FormInputDefault {...inputProps} />
    </InputLabelWrap>
  )
}

const FormInput: React.FC<Props> = ({
  name,
  displayName,
  type,
  helpText,
  isSecret = false,
  className,
  ...rest
}) => {
  const [field, meta] = useField(name)
  const [isSecretMasked, setSecretMasked] = useState(isSecret)

  const toggleSecret = useCallback(() => {
    setSecretMasked((cur) => {
      const newVal = !cur

      // Focus input after timeout for re-render
      setTimeout(() => {
        const input = document.querySelector(
          `input[name="${name}"]`
        ) as HTMLInputElement
        input?.focus()
      }, 10)

      return newVal
    })
  }, [setSecretMasked, name])

  const hasError = meta.touched && !!meta.error

  return (
    <div
      className={classNames(
        {
          'relative flex flex-col font-whitney w-full': true,
        },
        className
      )}
    >
      {type === 'file' &&
        renderFileInput({
          field,
          name,
          displayName,
          hasError,
          ...rest,
        })}

      {type !== 'file' &&
        renderInput({
          field,
          name,
          type,
          displayName,
          isSecret,
          isSecretMasked,
          toggleSecret,
          hasError,
          ...rest,
        })}

      {helpText && (
        <Text as="div" preset="body.xs sm:body.sm" className="text-gray-2 mt-1">
          {helpText}
        </Text>
      )}

      <FormError error={hasError ? (meta.error as string) : null} />
    </div>
  )
}

export default FormInput
