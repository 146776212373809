const primary = {
  white:
    'bg-white hover:bg-gray-8 focus-visible:ring-gray active:bg-gray disabled:bg-gray-7 text-black disabled:text-gray',
  black:
    'bg-black hover:bg-gray-2 focus-visible:ring-gray active:bg-gray-6 disabled:bg-gray-7 text-white disabled:text-gray',
  oxide:
    'bg-oxide hover:bg-oxide-1 focus-visible:ring-oxide-3 active:bg-oxide-3 disabled:bg-oxide-disabled text-white disabled:text-gray-6',
  copper:
    'bg-copper hover:bg-copper-1 focus-visible:ring-copper-3 active:bg-copper-3 disabled:bg-copper-disabled text-white disabled:text-gray-6',
  gray: 'bg-gray hover:bg-gray-4 focus-visible:ring-gray-6 active:bg-gray-2 disabled:bg-gray-8 text-white disabled:text-gray',
}

const secondary = {
  white:
    'border border-white text-white hover:bg-gray-2 focus-visible:ring-white active:bg-gray-2 active:text-gray-7 disabled:bg-gray-3 disabled:border-0 disabled:text-gray-6',
  black:
    'border border-black text-black hover:bg-gray-9 focus-visible:ring-black active:bg-gray-8 active:text-gray-3 disabled:bg-gray-8 disabled:border-0 disabled:text-gray-6',
  oxide:
    'border border-oxide text-oxide hover:bg-oxide-20 focus-visible:ring-oxide active:bg-oxide-20 active:text-oxide-1 disabled:bg-oxide-disabled disabled:border-0 disabled:text-gray-6',
  copper:
    'border border-copper text-copper hover:bg-copper-20 focus-visible:ring-copper active:bg-copper-20 active:text-copper-1 disabled:bg-copper-disabled disabled:border-0 disabled:text-gray-6',
  gray: 'border border-gray text-gray hover:bg-gray-9 focus-visible:ring-gray active:bg-gray-9 active:text-gray-2 disabled:bg-gray-9 disabled:border-0 disabled:text-gray-7',
}

const text = {
  white:
    'text-white border border-transparent hover:border-white focus-visible:ring-white active:text-gray-8 disabled:text-gray-3',
  black:
    'text-black border border-transparent hover:border-black focus-visible:ring-black active:text-gray-3 disabled:text-gray-7',
  oxide:
    'text-oxide border border-transparent hover:border-oxide focus-visible:ring-oxide active:text-oxide-1 disabled:text-oxide-disabled',
  copper:
    'text-copper border border-transparent hover:border-copper focus-visible:ring-copper active:text-copper-1 disabled:text-copper-disabled',
  gray: 'text-gray border border-transparent hover:border-gray focus-visible:ring-gray active:text-gray-3 disabled:text-gray-7',
}

const config = {
  primary: {
    ...primary,
    // re-cast legacy colors
    ctaAccent: primary.oxide,
    primary: primary.oxide,
    orange: primary.copper,
    // temp light gray until Photon update
    lightGray:
      'bg-gray-9 hover:bg-gray-8 focus-visible:ring-gray-8 active:bg-gray-7 disabled:bg-gray-10 text-black disabled:text-gray',
  },
  secondary: {
    ...secondary,
    // re-cast legacy colors
    ctaAccent: secondary.oxide,
    primary: secondary.oxide,
    orange: secondary.copper,
  },
  text,
}

export default config
