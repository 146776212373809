import { useEffect, useState } from 'react'
import { getSiteAssets, SiteAssets } from '@/services/api/siteAssets'
import SiteAssetsContext from './SiteAssetsContext'

const SiteAssetsProvider: React.FC<{
  assets?: SiteAssets
  children: React.ReactNode
}> = ({ assets, children }) => {
  const [siteAssets, setSiteAssets] = useState(assets)

  // not every page has getStaticProps, so we may need to fetch site assets
  useEffect(() => {
    async function fetchSiteAssets() {
      // TODO: should wrap in try/catch and determine what to do when this fails
      const res = await getSiteAssets()
      setSiteAssets(res)
    }

    if (assets) return

    fetchSiteAssets()
  }, [assets])

  return (
    <SiteAssetsContext.Provider value={siteAssets}>
      {children}
    </SiteAssetsContext.Provider>
  )
}

export default SiteAssetsProvider
