import { ISiteAssetsFields } from 'src/types/generated/contentful'
import { getSiteAssetsID } from '../../../../constants/contentful'
import { SiteAssets } from './types'

export const extraConfig = {
  'invest.angel.com': {
    zendeskKey: '361a1ab1-1121-48d7-a18c-c0ce46561cd0',
    cognitoFormsKey: '',
    cognitoFormsId: '',
    timeRemainingDescriptor: 'Left',
    fundingCampaignDisclaimer: '',
    footerDisclaimer: `This website is owned and operated by VAS Portal, LLC doing business as Angel Funding. All funding portal activities are conducted by VAS Portal, LLC dba Angel Funding. Any Reg A or Reg D offering listed on this website are managed by the broker dealer listed on the offering details.`,
    title: 'Angel Funding', // TODO: title is temporarily hard-coded as we determine how best to fetch assets for multiple sites
  },
  'thedavidmovie.com': {
    zendeskKey: '',
    cognitoFormsKey: 'E6D1XHWl6EK3GTVlg-eitw',
    cognitoFormsId: '4',
    timeRemainingDescriptor: 'Until Next Close',
    fundingCampaignDisclaimer: `*People includes all persons who have submitted subscription materials. DAVID may undertake one or more closings on a rolling basis. After each closing, investor funds will be made available to DAVID. After the initial closing of this Offering, we expect to hold closings on at least a monthly basis. Amount raised is an estimate that includes subscriptions, however not all such amounts have been accepted or closed, and some subscriptions may not be accepted.`,
    footerDisclaimer: `Slingshot USA, LLC is the issuer (the "Issuer") of the offering listed on the website with Dalmore Group, LLC as the Broker Dealer of Record. This technology platform is provided by Angel Funding for the Issuer.`,
    title: 'The David Movie',
  },
} as const

export const getSiteName = (): keyof typeof extraConfig =>
  getSiteAssetsID(
    process.env.NEXT_PUBLIC_SITE_DOMAIN
  ) as keyof typeof extraConfig

export const resolveSiteAssets = (assets: ISiteAssetsFields): SiteAssets => {
  return { ...assets, ...extraConfig[getSiteName()] }
}
