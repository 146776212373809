// This is a stylized rich text block

import { RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types'
import classnames from 'classnames'
import { report, richText } from '@/utils'

import Highlights from '@/components/pages/CampaignPages/DetailComponents/Highlights'
import Image from '@/components/contentful/Image'
import Text from '@/components/common/Text/Text'
import VideoBox from '../VideoBox'
import colors from '@/config/colors'

export interface Props {
  campaignSlug?: string
  className?: string
  document: Document
  optionOverrides?: RenderNode
}

const options = (campaignSlug = '', overrides?: RenderNode) => ({
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const fields = node?.data?.target?.fields
      const contentType = fields?.file?.contentType || ''

      if (fields && contentType.match(/^image/)) {
        return (
          <Image
            className="mb-8 -mx-6 w-screen lg:mx-0 lg:w-full"
            image={node.data.target}
            width={975}
            lazy
          />
        )
      } else {
        // TODO: support other embedded asset types
        report.log(`unsupported embedded asset: ${contentType}`)
      }
      return null
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      const { fields, sys } = node?.data?.target || {}
      const contentType = sys?.contentType?.sys?.id || ''

      if (contentType === 'videoBlock') {
        return (
          <VideoBox
            className="mb-8"
            campaignSlug={campaignSlug}
            id={`videobox-${fields.id}`}
            video={fields.id}
          />
        )
      } else {
        // TODO: support other embedded entry types
        report.log(`unsupported embedded entry: ${contentType}`)
      }
      return null
    },
    // h2-h4 are the only supported headings in Contentful at this time
    [BLOCKS.HEADING_2]: (node: any, children: any) => {
      return (
        <Text as="h2" preset="heading.lg" className="mt-10 mb-8">
          {children}
        </Text>
      )
    },
    [BLOCKS.HEADING_3]: (node: any, children: any) => {
      return (
        <Text as="h3" preset="heading.md" className="mt-10 mb-8">
          {children}
        </Text>
      )
    },
    [BLOCKS.HEADING_4]: (node: any, children: any) => {
      return (
        <Text as="h4" preset="heading.sm" className="mt-8 mb-8">
          {children}
        </Text>
      )
    },
    [BLOCKS.HR]: () => {
      return <hr className="mb-8" style={{ borderColor: colors.gray }} />
    },
    [BLOCKS.OL_LIST]: (node: any) => {
      const messages = node.content.map((listItem: any) =>
        // render the inner <p> as the message
        richText(listItem.content[0])
      )
      return <Highlights className="mb-8" highlights={messages} />
    },
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
      return (
        <Text as="p" preset="body.lg" className="mb-4 last:mb-0">
          {children}
        </Text>
      )
    },
    [BLOCKS.QUOTE]: (node: any, children: any) => {
      return (
        <blockquote className="border-l-[5px] border-solid border-l-lightGray mb-8 pl-6">
          {children}
        </blockquote>
      )
    },
    [BLOCKS.UL_LIST]: (node: any, children: any) => {
      return <ul className="list-disc my-8 ml-10 mr-0">{children}</ul>
    },
    [INLINES.HYPERLINK]: ({ data }: any, children: any) => {
      return (
        <a
          className="text-oxide-2 hover:text-oxide-1"
          target="_blank"
          rel="noreferrer"
          href={data.uri}
        >
          {children}
        </a>
      )
    },
    ...overrides,
  },
  renderText: (text: string) => {
    // converts \n characters to <br /> elements
    return text.split('\n').reduce((children: any, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
  },
})

const TextBlock = ({
  campaignSlug,
  className,
  document,
  optionOverrides,
}: Props) => {
  return (
    <div className={classnames('mb-4', className)}>
      {richText(document, options(campaignSlug, optionOverrides))}
    </div>
  )
}

export default TextBlock
