import React, { useCallback } from 'react'
import { useFormikContext } from 'formik'
import NumberFormat from 'react-number-format'
import { Input } from './FormBuilder/types'

// This component is for getting best results when integrating react-number-format and formik
// Related: https://github.com/s-yadav/react-number-format/issues/400

export interface Props extends Input {
  name: string
  mask?: string
  format?: string
  saveFormattedValue?: boolean
  onValueChange?: (value: string) => void
  onChange?: (e: React.ChangeEvent) => void
}

const FormNumberInput: React.FC<Props> = ({
  name,
  mask,
  format,
  saveFormattedValue = false,
  onValueChange,
  ...rest
}) => {
  const { setFieldValue } = useFormikContext()

  const handleValueChange = useCallback(
    ({ value, formattedValue }: { value: string; formattedValue: string }) => {
      const resolvedValue = saveFormattedValue ? formattedValue : value
      onValueChange
        ? onValueChange(resolvedValue)
        : setFieldValue(name, resolvedValue)
    },
    [setFieldValue, onValueChange, name, saveFormattedValue]
  )

  // Swallow onChange in case passed by Formik
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange, ...restSansOnChange } = rest

  return (
    <NumberFormat
      {...restSansOnChange}
      name={name}
      mask={mask}
      format={format}
      onValueChange={handleValueChange}
    />
  )
}

export default FormNumberInput
