import useApi, { ApiRes } from '@/services/hooks/useApiV2'
import {
  getUserInvestedCampaigns,
  Campaign,
} from '@/services/api/campaignV2/campaign'
import useAuth from '@/services/hooks/useAuth'

const useUserInvestedCampaigns = () => {
  const { user } = useAuth()

  const {
    data: userInvestedCampaigns,
    isLoading,
    isError,
  }: ApiRes<Campaign[]> = useApi({
    key: user ? `campaigns/${user.id}/` : false,
    request: getUserInvestedCampaigns,
  })

  return {
    userInvestedCampaigns,
    isLoading,
    isError,
  }
}

export default useUserInvestedCampaigns
