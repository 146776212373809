import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { getPortalEl } from '@/utils'

export interface Props {
  popoverRef?: Element | null
  children: React.ReactNode
}

const Portal: React.FC<Props> = ({ popoverRef, children }) => {
  const [hydrated, setHydrated] = useState(false)
  useEffect(() => setHydrated(true), [])

  if (!hydrated) return null

  return ReactDOM.createPortal(children, getPortalEl(popoverRef))
}

export default Portal
