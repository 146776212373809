import React, { useCallback, useState, useEffect } from 'react'
import classNames from 'classnames'
import { Listbox } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { TriangleDown } from '@/components/svg'
import { Input } from '../form/FormBuilder/types'
import { formUtil } from '@/utils'
import Text from '../Text/Text'
import Portal from '../Portal'
import ABTester, { ABTestProps } from '@/components/ABTester'
import { getInputClassName } from '@/components/common/form/InputLabelWrap/InputLabelWrap'

const PopoverBeacon = ({ onClose }: { onClose?: () => void }) => {
  useEffect(() => {
    // fire a scroll event to fire which helps the popover position itself correctly
    window.scrollTo(window.scrollX, window.scrollY + 1)
    window.scrollTo(window.scrollX, window.scrollY - 1)

    return () => {
      onClose?.()
    }
  }, [onClose])
  return null
}

export interface Option {
  label: string
  value: string
}

export interface Props extends Input {
  name: string
  options: Option[] | Option[][]
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  onClose?: () => void
  disabled?: boolean
  selectClassName?: string
}

const Select: React.FC<Props> = ({
  name,
  options,
  value,
  onChange,
  onClose,
  disabled,
  className,
  selectClassName,
}) => {
  const [targetRef, setTargetRef] = useState<HTMLButtonElement | null>(null)
  const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null)
  const { styles, attributes, state } = usePopper(targetRef, popperRef, {
    placement: 'bottom-start',
  })

  const selected = options
    .flat()
    .find(({ value: optionValue }) => optionValue === value)

  const grouped: Option[][] = formUtil.getGroupedOptions(options)

  const handleChange = useCallback(
    (value) => {
      const event = {
        target: { name, value },
      } as React.ChangeEvent<HTMLSelectElement>
      onChange(event)
    },
    [name, onChange]
  )

  return (
    <div
      className={classNames(
        {
          'opacity-50': disabled,
        },
        className
      )}
    >
      <Listbox
        value={selected?.value}
        onChange={handleChange}
        disabled={disabled}
      >
        <Listbox.Button
          ref={setTargetRef}
          className={classNames(
            'w-full flex items-center focus:outline-none text-left',
            selectClassName
          )}
          disabled
        >
          <div
            data-value={selected?.value}
            id={name}
            // Allow adding a name attr to this div as it contains the current value
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            name={name}
            aria-labelledby={`${name}-label`}
            className="truncate pr-2"
          >
            {selected?.label}
          </div>
          <TriangleDown
            className="text-gray ml-auto shrink-0"
            style={{ width: 10, height: 5 }}
            aria-hidden="true"
          />
        </Listbox.Button>

        <Portal popoverRef={targetRef}>
          <div
            ref={setPopperRef}
            style={{
              ...styles.popper,
              width: state?.rects?.reference?.width,
              zIndex: 9999,
            }}
            {...attributes.popper}
          >
            <Listbox.Options as="div">
              {({ open }) => (
                <div
                  className={classNames({
                    'relative font-whitney': true,
                    'opacity-100 pointer-events-auto': open,
                    'opacity-0 pointer-events-none': !open,
                  })}
                >
                  <PopoverBeacon onClose={onClose} />
                  <div className="overflow-auto bg-white rounded-sm border border-gray-8 mt-1 shadow-section max-h-[40vh] py-3 focus:outline-none">
                    {grouped.map((group, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <ul>
                            {group.map(({ label, value }) => (
                              <Listbox.Option key={value} value={value}>
                                {({ selected, active }) => {
                                  return (
                                    <Text
                                      as="div"
                                      preset="body.lg"
                                      className={classNames({
                                        'relative w-full flex items-center py-2 px-6 cursor-default':
                                          true,
                                        'bg-gray-9': active || selected,
                                      })}
                                      data-value={value}
                                    >
                                      {label}
                                    </Text>
                                  )
                                }}
                              </Listbox.Option>
                            ))}
                          </ul>

                          {idx < grouped.length - 1 && (
                            <hr className="my-1 mx-3 border-t-1 border-b-0 border-gray-8" />
                          )}
                        </React.Fragment>
                      )
                    })}
                  </div>
                </div>
              )}
            </Listbox.Options>
          </div>
        </Portal>
      </Listbox>
    </div>
  )
}

const ABSelect = ({ selectClassName, ...rest }: Props) => {
  // TODO: running into SSR hydration issues on the Campaign page
  // without this extra render hack. ABTester already uses a similar
  // `clientReady` flag, but it often returns true before hydration.
  const [rendered, setRendered] = useState(false)
  useEffect(() => setRendered(true), [])
  if (!rendered) return null

  return (
    <ABTester name="border_inputs">
      {({ decision }: ABTestProps) => {
        return (
          <Select
            {...rest}
            selectClassName={
              selectClassName || getInputClassName(decision.variationKey, false)
            }
          />
        )
      }}
    </ABTester>
  )
}

export default ABSelect
