const formatMoney = (num, options = {}) => {
  const isNonZeroFalsy = !num && num !== 0
  if (isNonZeroFalsy || isNaN(num)) return ''

  const {
    locale = 'en-US',
    currency = 'USD',
    maximumFractionDigits = 2,
    minimumFractionDigits = 2,
    isCents = true,
  } = options

  const amount = isCents ? num / 100 : num

  return amount.toLocaleString(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits,
    minimumFractionDigits,
  })
}

export default formatMoney
