import React, {
  useState,
  useCallback,
  forwardRef,
  useEffect,
  useRef,
} from 'react'
import YouTubePlayer from 'yt-player'
import classNames from 'classnames'
import Iframe from '@/components/common/Iframe'
import InView from '@/components/common/InView'
import useYoutubeBackgroundImage from '@/services/hooks/useYoutubeBackgroundImage'
import { AngelFundingLogo } from '@/components/svg'
import { getYoutubeVideoSegmentProperties, report } from '@/utils'
import SegmentHandler from '@/services/analytics/SegmentHandler'

const defaultAllow =
  'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen'

const defaultParams = {
  autoplay: 0,
  mute: 0,
  controls: 1,
  playsinline: 1,
  showinfo: 0,
  rel: 0,
  iv_load_policy: 3,
  modestbranding: 1,
  enablejsapi: 1,
}

export interface Props {
  id: string
  video: string
  aspectRatio?: number
  useThumbnailBackground?: boolean
  lazyload?: boolean
  fadeInOnLoad?: boolean
  className?: string
  style?: React.CSSProperties
  params?: Partial<typeof defaultParams> & { origin?: string }
  allow?: string
  campaignSlug: string
  doSegmentTracking?: boolean
}

const VideoBox = (
  {
    id,
    video,
    aspectRatio = 16 / 9,
    useThumbnailBackground = true,
    lazyload = true,
    fadeInOnLoad = true,
    className,
    style = {},
    params = {},
    allow = defaultAllow,
    campaignSlug,
    doSegmentTracking = true,
  }: Props,
  ref?: React.ForwardedRef<YouTubePlayer | null>
) => {
  const [inView, setInView] = useState(!lazyload)
  const { src: backgroundSrc, isLoaded: backgroundLoaded } =
    useYoutubeBackgroundImage({
      video,
      disabled: !inView || !useThumbnailBackground,
    })

  const forwardedPlayerRef = ref as { current: YouTubePlayer }
  const defaultPlayerRef = useRef(null)
  const playerRef = forwardedPlayerRef || defaultPlayerRef
  const wasPlayed = useRef(false)

  // If an error occurs in the youtube iframe api, then the iframe is removed
  // from the DOM, but React has no knowledge of the removal. This renderKey
  // is incremented in the iframe api error handler and is used to tell React
  // to rerender the iframe
  const [renderKey, setRenderKey] = useState(1)

  const showImage = useThumbnailBackground && backgroundLoaded

  const onEnter = useCallback(() => {
    if (lazyload) {
      setInView(true)
    }
  }, [lazyload, setInView])

  const paddingBottom = Number.isNaN(aspectRatio)
    ? `${(9 / 16) * 100}%`
    : `${100 / aspectRatio}%`

  const iframeSrc = Object.entries({ ...defaultParams, ...params }).reduce(
    (src, [key, val]) => `${src}&${key}=${val}`,
    `https://www.youtube.com/embed/${video}?`
  )

  const setupVideo = useCallback(() => {
    if (!document.getElementById(id) || !doSegmentTracking) return

    playerRef.current = new YouTubePlayer(`#${id}`)

    playerRef.current.load(video)

    playerRef.current.on('error', (err) => {
      setRenderKey((cur) => cur + 1)
      report.error(err as Error)
    })

    playerRef.current.on('playing', () => {
      if (wasPlayed.current) {
        return
      }

      wasPlayed.current = true

      SegmentHandler.track(
        'Video Content Started',
        getYoutubeVideoSegmentProperties(playerRef, campaignSlug)
      )
    })

    playerRef.current.on('paused', () => {
      SegmentHandler.track(
        'Video Playback Paused',
        getYoutubeVideoSegmentProperties(playerRef, campaignSlug)
      )
    })

    playerRef.current.on('ended', () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { position, ...videoProperties } = getYoutubeVideoSegmentProperties(
        playerRef,
        campaignSlug
      )
      SegmentHandler.track('Video Content Ended', videoProperties)
    })
  }, [id, video, playerRef, campaignSlug, doSegmentTracking])

  // re-initialize when video changes
  useEffect(() => {
    if (playerRef.current) {
      wasPlayed.current = false
      playerRef.current.destroy()
      setRenderKey((cur) => cur + 1)
      // timeout to allow iframe to render before binding YT api
      setTimeout(() => setupVideo(), 200)
    }
  }, [video, playerRef, setupVideo])

  useEffect(() => {
    if (inView) {
      setupVideo()
    }
  }, [inView, id, doSegmentTracking, setupVideo])

  return (
    <InView
      onEnter={onEnter}
      unobserveAfterEntry
      className={classNames('relative', className)}
      style={{ paddingBottom, ...style }}
    >
      {showImage && (
        <div
          className="absolute inset-0 w-full"
          style={{
            backgroundImage: `url(${backgroundSrc})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      )}

      <div
        className="absolute inset-0 bg-lightGray flex items-center justify-center p-6"
        style={{
          pointerEvents: showImage ? 'none' : 'auto',
          opacity: showImage ? 0 : 1,
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        <AngelFundingLogo fill="#B0B0B0" className="max-w-lg" />
      </div>

      {inView && (
        <div className="absolute inset-0" key={renderKey}>
          <Iframe
            id={id}
            data-cy="video-player"
            src={iframeSrc}
            allow={allow}
            className="absolute inset-0"
            width="100%"
            height="100%"
            fadeInOnLoad={fadeInOnLoad}
          />
        </div>
      )}
    </InView>
  )
}

export default forwardRef<YouTubePlayer | null, Props>(VideoBox)
