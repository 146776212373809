import useSWR, { SWRResponse, SWRConfiguration } from 'swr'

interface Props {
  key: string | false
  request: () => Promise<any>
  resolve?: (data: any) => any
  fallbackData?: any // TODO: fallbackData should be merged into swrOptions
  swrOptions?: SWRConfiguration // TODO: should be renamed `config`
}

export interface ApiRes<T> extends SWRResponse {
  data: T
  isLoading: boolean
  isError: boolean | Error
}

const useApi = ({ key, request, resolve, fallbackData, swrOptions }: Props) => {
  const fetcher = () =>
    request().then((data: any) => {
      return resolve ? resolve(data) : data
    })

  const { data, error, ...rest } = useSWR(key, fetcher, {
    errorRetryCount: 0,
    fallbackData,
    ...swrOptions,
  })

  return {
    data,
    error,
    isLoading: !error && !data,
    isError: error, // TODO: this should be a boolean; `error` object included in rest if needed
    ...rest,
  }
}

export default useApi
