import { Campaign } from '@/services/api/campaign'
import formatMoney from '../formatMoney'

const formatMoneyOpts = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}

export const getAmountRaised = ({
  regulation_type,
  capital_pledged,
  capital_raised,
  capital_goal,
}: Campaign) => {
  const capitalGoal = Number(capital_goal)
  const capitalRaised = Number(capital_raised)

  if (regulation_type === 'testing_the_waters') {
    return Number(capital_pledged)
  }

  return capitalRaised > capitalGoal ? capitalGoal : capitalRaised
}

export const getProgressString = ({
  regulation_type,
  capital_raised,
  capital_goal,
  share_minimum_target,
  share_price,
  share_goal,
}: Campaign) => {
  const capitalGoal = Number(capital_goal)
  const capitalRaised = Number(capital_raised)

  if (regulation_type === 'testing_the_waters') {
    return 'Expressed Interest'
  }

  if (capitalRaised > capitalGoal) {
    return `of ${formatMoney(Math.floor(capitalGoal), formatMoneyOpts)} MAX`
  }

  const shareMinimumTarget = Number(share_minimum_target)

  return capitalRaised > shareMinimumTarget * share_price
    ? `of ${formatMoney(
        Math.floor(share_goal * share_price),
        formatMoneyOpts
      )} max*`
    : `of ${formatMoney(
        Math.floor(shareMinimumTarget * share_price),
        formatMoneyOpts
      )} min*`
}

export const getTimeRemaining = ({
  hours_remaining_estimate,
  hours_until_close,
  days_remaining_estimate,
  days_until_close,
  capital_raised,
  capital_goal,
}: Campaign) => {
  const capitalGoal = Number(capital_goal)
  const capitalRaised = Number(capital_raised)
  const daysRemainingEstimate = Number(days_remaining_estimate)
  const daysUntilClose = Number(days_until_close)
  const hoursRemainingEstimate = Number(hours_remaining_estimate)
  const hoursUntilClose = Number(hours_until_close)

  if (hoursRemainingEstimate < 36 && capitalRaised < capitalGoal) {
    const hourOrHours = hoursRemainingEstimate === 1 ? 'Hour' : 'Hours'
    return {
      timeRemainingValue: hoursRemainingEstimate,
      timeRemainingDimension: hourOrHours,
    }
  }

  if (hoursUntilClose < 36) {
    const hourOrHours = hoursUntilClose === 1 ? 'Hour' : 'Hours'
    return {
      timeRemainingValue: hoursUntilClose,
      timeRemainingDimension: hourOrHours,
    }
  }

  if (daysRemainingEstimate < daysUntilClose && capitalRaised < capitalGoal) {
    const dayOrDays = daysRemainingEstimate === 1 ? 'Day' : 'Days'
    return {
      timeRemainingValue: daysRemainingEstimate,
      timeRemainingDimension: dayOrDays,
    }
  }
  const dayOrDays = daysUntilClose === 1 ? 'Day' : 'Days'
  return {
    timeRemainingValue: daysUntilClose,
    timeRemainingDimension: dayOrDays,
  }
}

export const getBackers = ({
  investors_total,
  users_pledged,
  regulation_type,
}: Campaign) => {
  return regulation_type === 'testing_the_waters'
    ? users_pledged
    : investors_total
}

export const getCapitalRaised = ({
  capital_goal,
  capital_pledged,
  capital_raised,
  regulation_type,
}: Campaign) => {
  const capitalGoal = Number(capital_goal)
  const capitalRaised = Number(capital_raised)

  if (regulation_type === 'testing_the_waters') {
    return Number(capital_pledged)
  }
  if (capitalRaised > Number(capitalGoal)) {
    return capitalGoal
  }
  return capitalRaised
}

export const getStretchGoal = ({
  stretch_goals = [],
  capital_raised,
}: Campaign) => {
  const capitalRaised = Number(capital_raised)

  if (stretch_goals === null || stretch_goals.length === 0) {
    return null
  }

  const campaignGoals = stretch_goals
    .sort((a, b) => {
      return a.amount > b.amount ? 1 : -1
    })
    .filter((goal) => goal.amount > capitalRaised)

  if (campaignGoals.length === 0) {
    return null
  }

  const { amount, title } = campaignGoals[0]

  return { amount: amount / 100, title }
}

export const getProgress = (campaign: Campaign) => {
  const { capital_raised, share_minimum_target, share_price, stretch_goals } =
    campaign
  const capitalRaised = Number(capital_raised)
  const shareMinimumTarget = Number(share_minimum_target)

  if (capitalRaised < shareMinimumTarget * share_price) {
    return (capitalRaised / (shareMinimumTarget * share_price)) * 100
  }

  if (!stretch_goals) {
    return 100
  }

  const stretchGoal = getStretchGoal(campaign)
  if (stretchGoal) {
    return capitalRaised / stretchGoal.amount
  } else {
    return 100
  }
}

export const getMinInvestment = ({
  share_per_person_min,
  share_price,
}: Campaign) => Math.floor(share_per_person_min * share_price)

export const getMaxInvestment = ({
  share_per_person_max,
  share_price,
}: Campaign) => Math.floor(share_per_person_max * share_price)

export const getIsSellingFast = ({
  hours_remaining_estimate,
  days_remaining_estimate,
  days_until_close,
  regulation_type,
  capital_raised,
  capital_goal,
}: Campaign) => {
  const capitalGoal = Number(capital_goal)
  const capitalRaised = Number(capital_raised)
  const daysRemainingEstimate = Number(days_remaining_estimate)
  const daysUntilClose = Number(days_until_close)
  const hoursRemainingEstimate = Number(hours_remaining_estimate)

  if (regulation_type === 'testing_the_waters') {
    return false
  }

  if (hoursRemainingEstimate < 36 && capitalRaised < capitalGoal) {
    return true
  }

  if (daysRemainingEstimate < daysUntilClose && capitalRaised < capitalGoal) {
    return true
  }

  return false
}

export const getIsInBuffer = ({
  capital_raised,
  capital_goal,
  capital_limit,
  currently_funding,
  regulation_type,
}: Campaign) => {
  const capitalGoal = Number(capital_goal)
  const capitalLimit = Number(capital_limit)
  const capitalRaised = Number(capital_raised)

  if (regulation_type === 'testing_the_waters') {
    return false
  }

  return (
    capitalRaised >= capitalGoal &&
    capitalRaised < capitalLimit &&
    currently_funding
  )
}

export const getPriorRaiseTotal = ({ prior_raises }: Campaign) => {
  if (prior_raises === null || prior_raises.length === 0) return null

  const total = prior_raises.reduce(
    (acc, cur) => ({
      amount: acc.amount + (cur.amount_raised || 0),
      backers: acc.backers + (cur.num_backers || 0),
    }),
    { amount: 0, backers: 0 }
  )

  return {
    ...total,
    amount: formatMoney(total.amount, formatMoneyOpts),
  }
}

export const getCreditCardFee = (amount: number) => amount * 0.0315 + 70
