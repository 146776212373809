import React, { useCallback } from 'react'
import classNames from 'classnames'
import { RadioGroup } from '@headlessui/react'
import { useFormikContext } from 'formik'
import { RadioOption } from '@/components/svg'
import { formUtil } from '@/utils'
import { Text } from '@/components/common'
import { Input } from '../FormBuilder/types'
import { Option } from '../../Select/Select'

export interface Props extends Input {
  name: string
  label: string | JSX.Element
  options: Option[] | Option[][]
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
}

const FormRadio: React.FC<Props> = ({
  name,
  label,
  options,
  value = '',
  onChange,
  disabled,
  className,
}) => {
  const { touched, setFieldTouched } = useFormikContext()

  const handleChange = useCallback(
    (value) => {
      if (!formUtil.lookupValue(touched, name)) {
        setFieldTouched(name, true)
      }

      const event = {
        target: { name, value },
      } as React.ChangeEvent<HTMLSelectElement>
      onChange(event)
    },
    [name, onChange, touched, setFieldTouched]
  )

  const grouped: Option[][] = formUtil.getGroupedOptions(options)

  return (
    <RadioGroup
      value={value}
      onChange={handleChange}
      disabled={disabled}
      className={className}
    >
      <RadioGroup.Label>
        <Text
          as="span"
          preset="custom"
          className="text-gray-5 text-xs leading-none"
        >
          {label}
        </Text>
      </RadioGroup.Label>
      <div className="flex flex-wrap">
        {grouped.map((group) => {
          return group.map(({ label: optionLabel, value: optionValue }) => (
            <RadioGroup.Option
              key={optionValue}
              value={optionValue}
              className="flex items-center mt-2 mr-8 cursor-pointer"
            >
              {({ checked }) => (
                <>
                  <RadioOption
                    className={classNames({
                      'w-3 h-3 mr-1': true,
                      'fill-oxide stroke-oxide': checked,
                      'stroke-gray fill-transparent': !checked,
                    })}
                  />
                  <Text as="span" preset="body.md">
                    {optionLabel}
                  </Text>
                </>
              )}
            </RadioGroup.Option>
          ))
        })}
      </div>
    </RadioGroup>
  )
}

export default FormRadio
