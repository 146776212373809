import { CampaignPayload } from '@/services/api/campaignV2/campaign'
import { Pledge } from '@/services/api/pledge'
import config from '../../../whiteLabel-config.json'

export const isWhiteLabel = () => !!process.env.NEXT_PUBLIC_SITE_DOMAIN

export const getWhiteLabelCampaigns = () => {
  const domain = process.env.NEXT_PUBLIC_SITE_DOMAIN as keyof typeof config
  return config[domain]
}

export const isAllowed = (obj: CampaignPayload | Pledge) => {
  const whiteLabelCampaigns = getWhiteLabelCampaigns()
  const slug = 'slug' in obj ? obj.slug : obj.campaign

  return whiteLabelCampaigns
    ? whiteLabelCampaigns.includes(slug)
    : !Object.values(config).flat().includes(slug)
}
