import { useState, useEffect } from 'react'
import checkImageAsync from '../../utils/checkImageAsync'

const useYoutubeBackgroundImage = ({
  video,
  size = 'maxresdefault',
  disabled = false,
}) => {
  const [isLoaded, setLoaded] = useState(false)
  const src = `https://img.youtube.com/vi/${video}/${size}.jpg`

  useEffect(() => {
    const checkImage = async () => {
      try {
        setLoaded(false)
        const img = await checkImageAsync(src)
        // If image not found, Youtube will still send a generic default image
        // at 120x90, so check that image is bigger than that
        if (img && img.width > 120 && img.height > 90) {
          setLoaded(true)
        }
      } catch (e) {
        setLoaded(false)
      }
    }

    if (!disabled) {
      checkImage()
    }
  }, [src, disabled, setLoaded])

  return {
    src,
    isLoaded,
  }
}

export default useYoutubeBackgroundImage
