import { createContext } from 'react'
import { SiteAssets, extraConfig, getSiteName } from '@/services/api/siteAssets'

const SiteAssetsContext = createContext<SiteAssets | undefined>({
  contentfulName: 'invest.angel.com',
  electronicConsent: '',
  howCrowdfundingWorks: '',
  portalDisclosures: '',
  privacy: '',
  termsOfService: '',
  termsOfServiceIntro: '',
  ...extraConfig[getSiteName()],
})

export default SiteAssetsContext
